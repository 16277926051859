<template >
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
             {{$t("message.tms")}}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
          <el-form
            ref="form"
            status-icon
            :model="form"
            :rules="rules"
            v-loading="loadingData"
          >
            <el-row :gutter="20">
              <el-col :span="12">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                  {{ $t("message.type") }}
                </span>
                <el-form-item prop="type">
                  <el-select
                  :class="mode ? 'input__day' : 'input__night'"
                    v-model="form.type"
                    :placeholder="$t('message.type')"
                    filterable
                    :size="'small'"
                    class="d-block"
                  >
                    <el-option
                      v-for="(type, index) in types"
                      :key="'type-' + index"
                      :label="type.label"
                      :value="type.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                  {{ $t("message.transport_type") }}
                </span>
                <el-form-item prop="transport_type">
                  <el-select
                  :class="mode ? 'input__day' : 'input__night'"
                    @change="clearTransport()"
                    v-model="form.transport_type"
                    :placeholder="$t('message.type')"
                    filterable
                    :size="'small'"
                    class="d-block"
                  >
                    <el-option
                      v-for="(type, index) in transport_types"
                      :key="'type-' + index"
                      :label="type.label"
                      :value="type.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col
                :span="12"
                v-if="form.transport_type == 'client_transport'"
              >
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                  {{ $t("message.client_transport") }}
                </span>
                <el-form-item prop="transport_id" class="label_mini">
                  <select-guest-car
                    :id="form.transport_id"
                    v-model="form.transport_id"
                    :placeholder="$t('message.client_transport')"
                    @staffId="setStaffId"
                  ></select-guest-car>
                </el-form-item>
              </el-col>
              <el-col
                :span="12"
                v-if="form.transport_type == 'company_transport'"
              >
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                  {{ $t("message.company_transport") }}
                </span>
                <el-form-item prop="transport_id" class="label_mini">
                  <select-transport
                    :id="form.transport_id"
                    v-model="form.transport_id"
                    :placeholder="$t('message.company_transport')"
                    @staffId="setStaffId"
                  ></select-transport>
                </el-form-item>
              </el-col>
              <el-col
                :span="12"
               
              >
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                  {{ $t("message.car_driver") }}
                </span>
               <el-form-item prop="driver" class="label_mini">
                    <crm-input
                     :class="mode ? 'input__day' : 'input__night'"
                      :inputValue="form.driver"
                      v-model="form.driver"
                      :placeholder="$t('message.car_driver')"
                    ></crm-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="12">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
                  {{ $t("message.left_date") }}
                </span>
                <el-form-item prop="left_date" class="label_mini">
                  <crm-date-picker
                    :date="form.left_date"
                    :placeholder="$t('message.left_date')"
                    v-model="form.left_date"
                  >
                  </crm-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
                  {{ $t("message.left_time") }}
                </span>
                <el-form-item prop="left_time" class="label_mini">
                  <el-time-picker
                    value-format="HH:mm:ss"
                    format="HH:mm:ss"
                    size="small"
                    :placeholder="$t('message.left_time')"
                    v-model="form.left_time"
                    style="width: 100%"
                  ></el-time-picker>
                </el-form-item>
              </el-col> -->

              <el-col :span="12"  v-if="form.type == 'exit'">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
                  {{ $t("message.left_date") }}
                </span>
                <el-form-item prop="left_date" class="label_mini">
                  <crm-date-picker
                    :date="form.left_date"
                    :placeholder="$t('message.left_date')"
                    v-model="form.left_date"
                    :class="mode ? 'input__day' : 'input__night'"
                  >
                  </crm-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="form.type == 'exit'">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
                  {{ $t("message.left_time") }}
                </span>
                <el-form-item prop="left_time" class="label_mini">
                  <el-time-picker
                    value-format="HH:mm:ss"
                    format="HH:mm:ss"
                    size="small"
                    :placeholder="$t('message.left_time')"
                    v-model="form.left_time"
                    style="width: 100%"
                    :class="mode ? 'input__day' : 'input__night'"
                  ></el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12"  v-if="form.type == 'entrance'">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
                  {{ $t("message.arrival_date") }}
                </span>
                <el-form-item prop="arrival_date" class="label_mini">
                  <crm-date-picker
                    :date="form.left_date"
                    :placeholder="$t('message.arrival_date')"
                    v-model="form.arrival_date"
                    :class="mode ? 'input__day' : 'input__night'"
                  >
                  </crm-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="form.type == 'entrance'">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
                  {{ $t("message.arrival_time") }}
                </span>
                <el-form-item prop="arrival_time" class="label_mini">
                  <el-time-picker
                    value-format="HH:mm:ss"
                    format="HH:mm:ss"
                    size="small"
                    :placeholder="$t('message.arrival_time')"
                    v-model="form.arrival_time"
                    :style="'width: 100%'"
                    :class="mode ? 'input__day' : 'input__night'"
                  ></el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
                  {{ $t("message.region") }}
                </span>
                <el-form-item prop="region_id">
                  <select-region
                    :id="form.region_id"
                    v-model="form.region_id"
                    :size="'small'"
                  >
                  </select-region>
                </el-form-item>
              </el-col>
              <el-col :span="2">
                  <div class="pulus " style="height: 33px;  margin-top: 20px;" @click="regionCreateModel = true" :class="mode ? 'pulus__myday' : 'pulus__mynight'">
                    <i class="el-icon-plus"></i>
                  </div>
                </el-col>
              <el-col :span="10">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
                  {{ $t("message.district") }}
                </span>
                <el-form-item prop="district_id">
                  <select-district
                    :region_id="form.region_id"
                    v-model="form.district_id"
                    :id="form.district_id"
                    :size="'small'"
                  ></select-district>
                </el-form-item>
              </el-col>
                <el-col :span="2">
                  <div class="pulus " style="height: 33px;  margin-top: 20px;" @click="districtCreateModel = true" :class="mode ? 'pulus__myday' : 'pulus__mynight'">
                    <i class="el-icon-plus"></i>
                  </div>
                </el-col>
              <el-col :span="12">
                      <select-company
                        :id="form.company_id"
                        v-model="form.company_id"
                      ></select-company>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <p>{{ $t("message.product") }}</p>
                <hr />
              </el-col>
              <div v-for="(pro, index) in form.items" :key="index" v-if="pro.type == form.type">
                <el-col :span="7">
                  <el-form-item>
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
                      {{ $t("message.product_name") }}
                    </span>
                    <crm-input
                     :class="mode ? 'input__day' : 'input__night'"
                      :placeholder="$t('message.product_name')"
                      v-model="pro.name"
                      :inputValue="pro.name"
                      :size="'small'"
                    ></crm-input>
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <el-form-item>
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
                      {{ $t("message.quantity") }}
                    </span>
                    <crm-input
                     :class="mode ? 'input__day' : 'input__night'"
                      :placeholder="$t('message.quantity')"
                      v-model="pro.quantity"
                      :inputValue="pro.quantity"
                      :size="'small'"
                    ></crm-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item>
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
                      {{ $t("message.measurement_type") }}
                    </span>
                    <crm-input
                     :class="mode ? 'input__day' : 'input__night'"
                      :placeholder="$t('message.measurement_type')"
                      v-model="pro.measurement_type"
                      :inputValue="pro.measurement_type"
                      :size="'small'"
                    ></crm-input>
                  </el-form-item>
                </el-col>
                <el-col class="p-4" :span="1">
                  <el-button
                    :disabled="form.items.length == 1 ? true : false"
                    class="mt-4"
                    type="danger"
                    size="medium"
                    @click="form.items.splice(index, 1)"
                    icon="el-icon-minus"
                    circle
                  >
                  </el-button>
                </el-col>
                <el-col class="p-4" :span="1">
                  <el-button
                    class="ml-2 mt-4"
                    type="primary"
                    size="medium"
                    @click="
                      form.items.push({
                        name: null,
                        measurement_type: null,
                        quantity: null,
                        type:form.type
                      })
                    "
                    circle
                    icon="el-icon-plus"
                  >
                  </el-button>
                </el-col>
              </div>
            </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>

      <el-dialog
        :visible.sync="regionCreateModel"
        width="60%"
        :append-to-body="true"
        center
        ref="regionCreateModel"
        :class="mode ? 'dialog__myday' : 'dialog__mynight'"
      >
        <create-region
          @closeRegionModal="closeRegionModal"
          ref="regionCreateModelChild"
          dialog="regionCreateModel"
        ></create-region>
      </el-dialog>

      <el-dialog
        :visible.sync="districtCreateModel"
        width="60%"
        :append-to-body="true"
        center
        ref="districtCreateModel"
        :class="mode ? 'dialog__myday' : 'dialog__mynight'"
      >
        <create-district
          @closeDistrictModal="closeDistrictModal"
          ref="districtCreateModelChild"
          dialog="districtCreateModel"
        ></create-district>
      </el-dialog>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import selectTransport from "@/components/filters/inventory/select-transport";
import selectCompany from "@/components/filters/inventory/select-or-hidden-company";
import selectStaff from "@/components/filters/inventory/select-staff";
import createRegion from "../../employeeKpp/components/create-region.vue";
import createDistrict from "../../employeeKpp/components/create-district.vue";
import show from "@/utils/mixins/show";
import { i18n } from "@/utils/i18n";
export default {
  mixins: [form, drawer, show],
  name: "TmsController",
  components: { selectTransport, selectStaff, selectCompany, createRegion, createDistrict },
  data() {
    return {
       dialogCreateGuest: false,
      dialogCreateModel: false,
      regionCreateModel:false,
      districtCreateModel:false,
      types: [
        {
          label: i18n.t("message.entrance"),
          value: "entrance",
        },
        {
          label: i18n.t("message.exit"),
          value: "exit",
        },
      ],
      transport_types: [
        {
          label: i18n.t("message.client_transport"),
          value: "client_transport",
        },
        {
          label: i18n.t("message.company_transport"),
          value: "company_transport",
        },
      ],
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      rules: "tms/rules",
      model: "tms/model",
      columns: "tms/columns",
      mode: "MODE"
    }),
  },
  methods: {
    ...mapActions({
      save: "tms/update",
      empty: "tms/empty",
      show: "tms/show",
    }),
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              if (res.status == 201) {
                this.parent().listChanged();
                this.close();
                this.empty();
              }
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
        console.log(this.loadingButton);
      });
    },
    setStaffId(staff_id) {
      this.form.staff_id = staff_id;
    },
    clearTransport(value) {
      this.form.transport_id = "";
    },
    afterLeave() {
      this.items = [];
      this.items.push({
        name: "",
        measurement_type: null,
        quantity: null,
      });
    },
    closeRegionModal(val) {
      this.regionCreateModel = val;
    },
    closeDistrictModal(val) {
      this.districtCreateModel = val;
    },
  },
};
</script>

